import TransactionLineDetail from '@/domain/TransactionLineDetail';
import TransactionLineDTO from '@/modules/floortrak/domain/jigsaw/dto/TransactionLineDTO';
import Item from '@/domain/Item';
import TrackedItem from './TrackedItem';

export type TransactionLineInit = {
    item: Item;
    plannedQuantity?: number;
    actualQuantity?: number;
    requestedQuantity?: number;
    estimatedQuantity?: number;
    id?: number;
    receivedQuantity?: number;
    transactionLineDetails?: Array<TransactionLineDetail>;
    unitLoadParentId?: number;
};

export default class TransactionLine {
    public id!: number;

    public item: Item;

    private f_transactionLineDetails: Array<TransactionLineDetail> = new Array<TransactionLineDetail>();

    public plannedQuantity!: number;

    private f_actualQuantity!: number;

    public requestedQuantity!: number;

    public receivedQuantity!: number;

    public estimatedQuantity!: number;

    private f_unitLoadParentId?: number;

    public estimateAdjustment: number = 0;

    constructor(init: TransactionLineInit) {
        this.item = init.item;
        if (init.id) this.id = init.id;
        // TODO: does this make sense?
        this.f_actualQuantity = typeof init.actualQuantity === 'number' ? init.actualQuantity : 0;
        if (typeof init.plannedQuantity === 'number') this.plannedQuantity = init.plannedQuantity;
        if (typeof init.requestedQuantity === 'number') this.requestedQuantity = init.requestedQuantity;
        if (typeof init.receivedQuantity === 'number') this.receivedQuantity = init.receivedQuantity;
        if (typeof init.estimatedQuantity === 'number') this.estimatedQuantity = init.estimatedQuantity;
        if (init.transactionLineDetails) this.f_transactionLineDetails = init.transactionLineDetails;
        this.f_unitLoadParentId = init.unitLoadParentId;
    }

    public addLineDetail(newLineDetail: TransactionLineDetail) {
        const existingLineDetail = this.getLineDetailByBarcode(newLineDetail.trackedItem.barcode);
        if (existingLineDetail) {
            alert(`Tracked Item: ${newLineDetail.trackedItem.barcode} already exists on this transaction`);
        } else {
            this.f_transactionLineDetails.unshift(newLineDetail);
            this.f_actualQuantity++;
        }
    }

    public removeLineDetail(lineDetail: TransactionLineDetail) {
        const index = this.f_transactionLineDetails.findIndex((detail) => detail.id === lineDetail.id);
        this.f_transactionLineDetails.splice(index, 1);
        this.f_actualQuantity--;
    }

    public getLineDetailByBarcode(barcode: string) : TransactionLineDetail | undefined {
        return this.f_transactionLineDetails.find((lineDetail) => lineDetail.trackedItem.barcode === barcode);
    }

    public clearActualQuantity() {
        this.f_actualQuantity = 0;
    }

    get transactionLineDetails() : Array<TransactionLineDetail> {
        return this.f_transactionLineDetails;
    }

    get numberOfTags(): number {
        return this.f_transactionLineDetails.length;
    }

    get actualQuantity(): number {
        return this.f_actualQuantity;
    }

    set actualQuantity(value: number) {
        if (value >= this.numberOfTags) {
            this.f_actualQuantity = value;
        } else {
            this.f_actualQuantity = this.numberOfTags;
        }
    }

    get tagList(): Array<string> {
        return this.f_transactionLineDetails.map((tld) => tld.trackedItem.barcode);
    }

    get trackedItemList(): Array<TrackedItem> {
        return this.f_transactionLineDetails.map((detail) => detail.trackedItem);
    }

    get unitLoadParentId(): number | undefined {
        return this.f_unitLoadParentId;
    }

    toDTO(): TransactionLineDTO {
        return new TransactionLineDTO(this);
    }
}
