import Location from './Location';
import { TransactionStatus } from './TransactionStatus';

export type Direction = 'inbound' | 'outbound';

export default abstract class TransactionAbstractShare {
    abstract fromLocationId: number;

    abstract hasShipped: boolean;

    abstract status: TransactionStatus;

    abstract toLocationId: number;

    public canAdminAdjust(): boolean {
        return this.status >= TransactionStatus.HELD
            && this.status !== TransactionStatus.DELETED;
    }

    public canAdminEdit(userLocation: Location): boolean {
        if (this.status >= TransactionStatus.CONFIRMED) return false;
        if (this.getDirection(userLocation) === 'inbound' && this.status <= TransactionStatus.IN_TRANSIT) return false;
        if (this.getDirection(userLocation) === 'outbound' && this.status >= TransactionStatus.HELD) return false;
        if (this.getDirection(userLocation) === 'inbound' && this.toLocationId !== userLocation.id) return false;
        if (this.getDirection(userLocation) === 'outbound' && this.fromLocationId !== userLocation.id) return false;
        if (!this.isMyLocation(userLocation)) return false;
        return true;
    }

    public canAdminEditDockAndTimeslot(userLocation: Location): boolean {
        if (this.status >= TransactionStatus.CONFIRMED) return false;
        if (this.getDirection(userLocation) === 'inbound' && this.status < TransactionStatus.IN_TRANSIT) return false;
        if (this.getDirection(userLocation) === 'outbound' && this.status >= TransactionStatus.HELD) return false;
        if (this.getDirection(userLocation) === 'inbound' && this.toLocationId !== userLocation.id) return false;
        if (this.getDirection(userLocation) === 'outbound' && this.fromLocationId !== userLocation.id) return false;
        if (!this.isMyLocation(userLocation)) return false;
        return true;
    }

    public canEdit(userLocation: Location): boolean {
        if (this.hasShipped) return false;
        if (this.status >= TransactionStatus.ALLOCATED && this.getDirection(userLocation) === 'inbound') return false;
        return true;
    }

    public getDirection(userLocation: Location): Direction | null {
        if (userLocation.id === this.toLocationId) {
            return 'inbound';
        }
        if (userLocation.id === this.fromLocationId) {
            return 'outbound';
        }
        return null;
    }

    public isMyLocation(userLocation: Location): boolean {
        return userLocation.id === this.fromLocationId || userLocation.id === this.toLocationId;
    }
}
